jq.exhibitHall = { init : function() {

	var halls = handover.halls;

	$('.frames').flickity({
		cellSelector: '.frame',
		prevNextButtons: true,
	  	pageDots: false,
	  	selectedAttraction: 0.1,
		friction: 0.5,
		freeScroll: true,
	});
	$frames = $('.frames').data('flickity');

	var buildNav = function() {
		for (i = 0; i < $frames.cells.length; i++) {
			var e = $frames.cells[i].element;
            var n = $(e).data('n');
            var b = e.children[0];
			var w = b.clientWidth; h = b.clientHeight; c = (b.className == 'preface' ? 'inf' : '');
            var s = ( $('.exhibit-monitor').length ) ? 18 : 30 ;
            var t = $(b).find('.titulo').text()
			$('.line-nav .halls-nav').append('<div class="box-item '+c+'" data-title="'+t+'" data-idx="'+i+'"> <div class="box" style="width:'+w/s+'px;height:'+h/s+'px" > </div> <div class="numer">'+n+'</div> </div>');
        }

		$('.halls-nav').flickity({
			asNavFor: '.frames',
			cellAlign: 'left',
			prevNextButtons: false,
			pageDots: false,
			selectedAttraction: 0.4,
			friction: 1,
		});

		var $f = $('.frames .frame'); $hn = $('.halls-nav .box-item'); p = 0;
		for (i = 0; i < halls.length; i++) {
			frm = halls[i].frames;
			$f.slice(p, p+frm).wrapAll('<div class="hall" data-img="'+halls[i].img+'"></div>');
			$hn.slice(p, p+frm).wrapAll('<div class="frames-nav"></div>');
			p += frm;
		}

        var $nf = $('.halls-nav .frames-nav');
        for (i = 0; i < $nf.length; i++) {
            var e = $nf[i];
            var t = $(e).find('.inf').data('title');
            $(e).append('<div class="box-frame">'+t+'</div>');
        }
        
		var title = $('.line-nav').data('title');
		var divs = '<div class="titulo"><span>'+title+'</span></div> <div class="frames-star"></div>';
		$('.line-nav .halls-nav .frames-nav:first-child').prepend(divs);
        
	};

	var funcNav = function() {
		$frames.on('cellSelect', function() {
			$('.frames .hall, .halls-nav .frames-nav').removeClass('select');
			$('.frames .is-selected').closest('.hall').addClass('select');
			$('.halls-nav .is-selected').closest('.frames-nav').addClass('select');
			backGrdHall();
		});
		$frames.on('dragMove', function() {
			var x = $frames.x; p = $frames.getPositionValue(x); n = parseInt(p.slice(0, -1));
			if( n >= '-15' ) {	$('.timeline').removeClass('halls');}            
		});
		$frames.on('dragStart', function() {
		    $('.frame .imag').css({'z-index':3});
            clearTimeout(arst); $('.arraste').removeClass('showing');
		});
		$frames.on('dragEnd', function() {
            $('.frame .imag').css({'z-index':1});
		});
		$('.intro .btn.enter, .intro .next').on('click', function() {
			$('.timeline').addClass('halls');
			$('.halls-nav .frames-nav:eq(0)').addClass('select');
			$frames.select(0);
		});
        $('.halls-nav .frames-nav .box-frame').on('click', function() {
            $('.timeline').addClass('halls');
            var idx = $(this).closest('.frames-nav').find('.inf').data('idx')
            $frames.select(idx);
        });
		$('.halls-nav .frames-nav .box-item').on('click', function() {
			$('.timeline').addClass('halls');
		});
		$('.frames .prev, .frames-star').on('click', function() {
			$('.timeline').removeClass('halls');
			$frames.select(0);
		});
	};

    var backGrdHall = function() {
        var aimg = $('.backgrdhall').css('background-image');
        var himg = $('.frame.is-selected').closest('.hall').data('img');
        if( aimg !== 'url("'+himg+'")' ){
            $('.backgrdhall').fadeOut(200, function() {
                $(this).css('background-image','url('+himg+')').fadeIn(400);
            });
        }
    };

	$(window).on('load', function() {
		buildNav(); funcNav(); $('.frames').flickity('reloadCells');
	});

}};

jq.virtual50anos = { init : function() {

	var elem = $();
    var player50 = $('.display .screenplayer').jPlayer({
        cssSelectorAncestor: '.display .player',
        supplied: 'm4v',
        useStateClassSkin: true,
        autoBlur: false,
        smoothPlayBar: true,
        keyEnabled: true,
        toggleDuration: true,
        wmode: 'transparent',
        size: {
            width: '100%',
            height: '100%',
        },
        loadstart: function() {
            $('.jp-video-loading').show();
            $('.play').addClass('active');
        },
        loadeddata: function(event) {
            player50.jPlayer('playHead',0);
            $('.jp-video-loading').hide();
            $('.play').removeClass('active');
        },
        ended: function() {
            $('.play').removeClass('active');
            elem.next().click();
        },
    });

    $(window).on('load', function() {
        runFirst();
        //LAZY LOADING imagens
        var imgDefer = $('img.lazy');
        for (var i=0; i<imgDefer.length; i++) {
            if( imgDefer[i].getAttribute('data-src') ) {
                imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src') );
            }
        }
    });

	$('.line-nav li').on('click', function(){
        $('.line-nav li').removeClass('active');
        elem = $(this);
		elem.addClass('active');
        index = elem.index();
        video = elem.data('video');
        $('.dots ul').fadeOut(0);
        $('.dots .area'+index+'').fadeIn(0);
        player50.jPlayer('setMedia', {m4v: video});
        lineBar(index-1, 200);
    });

    $('.line-nav .play .btn').on('click', function(){
        if( elem.is(':last-child') ){
            runFirst();
        } else {
            $('.line-nav li').removeClass('active');
            //$('.line-nav li.active').index();
            player50.jPlayer('play');
            t = player50.data("jPlayer").status.duration;
            lineBar(index,t*1000);
        }
        $('.play').addClass('active');
        $('.dots ul').fadeOut(250);
    });

    function lineBar(i,t) {
        $('.line-nav li').removeClass('pass');
        $('.line-nav li:eq('+i+')').prevAll().addClass('pass');
    	var d = $('.line-nav li').length-1;
    	var e = 100/d;
    	var w = e*(i);
    	$('.line-nav .line .bar').stop(true, false).animate({width: w+'%'}, t);
    }

    function runFirst() {
        $('.line-nav li:eq(0)').click();
        if(jQuery.isEmptyObject(desktop)){
            setTimeout( function(){
                $('.line-nav .play .btn').click();
            },500);
        }
    }

    $('.area1 .play').on('click', function(){
        $('.line-nav .play .btn').click();
    });

}};
