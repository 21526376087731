
jq.studio = {
    init: function () {

        // Deal with different browsers:
        var promisifiedOldGUM = function (constraints) {
            // First get ahold of getUserMedia, if present
            var getUserMedia = (navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia
            );

            

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }

            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise(function (resolve, reject) {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });

        };


        // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = promisifiedOldGUM;
        }

        var constraints = {
            audio: true,
            video: true,
        };

        var streamSrc;

        var getNews = true;
        var callNews = 0;

        var news;
        var session;
        var iVideo = 1;

        var videoBuffer;
        var videoFilesBlob = {
            count: 0
        };

        var photoBlob = null;

        var mediaStream;
        var mediaRecorder;

        var canvas = {
            el: $('#capture-image')[0],
            width: null,
            height: null,
        };

        var showStudioTips = handover.showStudioTips;
        var hintStep = 1;
        var hintFinalStep = 3;

        // Bullets
        $('.start .noticias span').on('click', function () {
            $('.start .noticias span').removeClass('active');
            $(this).addClass('active');
        });


        $('.btn.repetir').on('click', function () {
            $('section').removeClass('showing');
            $('.msgs.start').addClass('showing');
            $('.area.ar-ok').fadeIn(250);
            $('.area.ar-erro').fadeOut(250);
        });

        $('.msgs.finish .send-btn').click(function () {
            $('.send-btn').attr('disabled', true).find('icon').removeClass('seta_grande_dir').addClass('spin anim-spin');
            API.finishRecord(session.id, $('#finish-recording').serialize(), function (status, data) {
                if (status == 'success') {
                    $('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
                    $('.msgs.finish').removeClass('showing'); $('.msgs.success').addClass('showing');
                    $('#finish-recording')[0].reset();
                } else {
                    $('.form-group').removeClass('has-error');
                    $.each(data.responseJSON, function (key, val) {
                        if (key === key) {
                            $('.form-group #' + key + '').closest('.form-group').addClass('has-error').find('.help-block').text(val);
                        }
                    });
                    $('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
                }
            });

        });

        $(window).on('load', function () {
            $('.ecran').on('canplay', function (ev) {
                canvas.width = $(this)[0].videoWidth;
                canvas.height = $(this)[0].videoHeight;

                setCanvas();
            });
        });

        $('.btn.record').on('click', function (e) {


            var acceptanceElementId = $(this).data('accept_control');
            acceptanceElement = $('#' + acceptanceElementId);
            if (!acceptanceElement.is(":checked")) {
                console.log('Acceptance is required');
                var acceptanceContainer = acceptanceElement.parent();
                acceptanceContainer.css('color', 'red');
                acceptanceContainer.children('a').css('color','red');
                e.preventDefault();
                return false;
            }
            console.log('ok to record');
            startVideoStream(function (status, err) {
                
                if (status == 'success') {
                    var newsNum = $('.start .noticias .active').data('num');
                    API.startRecord(newsNum, function (status, data) {
                        news = data.newsToShow;
                        session = data.session;
                        start();
                    });
                    $('section').removeClass('showing');
                    $('section.estudio').addClass('rec');
                }
                else {
                    console.log('Status is '+status);
                    console.log('The following occurred:');
                    console.log(err);
                    if (err.name == 'SecurityError') {
                        alert('Precisa de dar autorização');
                    }
                }
            });
        });

        function startVideoStream(callback) {
            
            navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    mediaStream = stream;
                    var url = window.URL || window.webkitURL;
                    streamSrc = url ? url.createObjectURL(stream) : stream;
                    $('.ecran, .monit').attr('src', streamSrc);
                    initMediaRecorder(stream);
                    callback('success', null);
                })
                .catch(function (err) {
                    callback('error', err);
                });
        }

        function initMediaRecorder(stream) {

            var options = {};
            if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                options = { mimeType: 'video/webm;codecs=vp9' };
            } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                options = { mimeType: 'video/webm;codecs=vp8' };
            }

            mediaRecorder = new MediaRecorder(stream, options);

            videoBuffer = [];

            mediaRecorder.ondataavailable = function (e) {
                videoBuffer.push(e.data);
            };

            mediaRecorder.onstop = function (e) {
                var currentHash = "" + session.id + news[callNews - 1].id + iVideo;
                videoFilesBlob[currentHash] = {
                    blob: new Blob(videoBuffer, { 'type': 'video/webm; codecs="vorbis, vp8"' }),
                    newsId: news[callNews - 1].id
                };

                videoFilesBlob.count++;
                videoBuffer = [];

                uploadVideoBlob(currentHash);
                iVideo++;
            };

            mediaRecorder.onwarning = function (e) {
            };

            mediaRecorder.onerror = function (e) {
            };
        }

        function uploadVideoBlob(hash, callback) {
            var videoBlob = videoFilesBlob[hash].blob;
            var fd = new FormData();
            fd.append('video', videoBlob);
            fd.append('news', videoFilesBlob[hash].newsId);
            fd.append('hash', hash);

            API.uploadVideo(session.id, fd, function (status, data) {
                if (status == 'success') {
                    delete videoFilesBlob[data.hash];
                    videoFilesBlob.count--;
                }

                if (typeof callback !== 'undefined') {
                    callback(status);
                }
            });
        }

        function uploadPhotoBlob() {
            var fd = new FormData();
            fd.append('photo', photoBlob);

            API.uploadStudioPhoto(session.id, fd, function (status, data) {
                if (status == 'success') {
                }
            });
        }

        function countdown(initVal, callback) {
            var countdw = initVal;
            var countIt = function () {
                setTimeout(function () {
                    countdw--;
                    $('.teleponto').html('<div class="numer">' + countdw + '</div>');
                    if (countdw === 0) {
                        callback();
                    } else {
                        countIt();
                    }
                }, 1000);
            };
            countIt();
        }

        function start() {
            if (showStudioTips) {
                showTip();
            } else {
                countdown(6, function () {
                    takeSnapshot(uploadPhotoBlob);
                    startRec();
                });
            }
        }

        function showTip() {
            $('.hints .hint').hide();
            if (hintStep > hintFinalStep) {
                $.ajax({
                    type: 'POST',
                    url: handover.urls['studio-tips-session'],
                    data: { _token: handover.token },
                    dataType: 'json',
                    success: function (data) {
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                    },
                });
                showStudioTips = false;
                start();
                return;
            }

            $('.hints .ht0' + hintStep).show();
            hintStep++;
        }

        $('.hints .hint .btn').on('click', start);

        function startRec() {
            $('.camara .dot').addClass('star');
            mediaRecorder.start();
            timeRec();
            newNews();
            var destaques = [];
            $.each(news, function (index, data) {
                destaques.push(data.title);
            });

            highlights(destaques.join(' • '));
        }

        function highlights(text) {
            $('.destaques .scrolltext').text(text);
            $('.destaques').bind('marquee', function () {
                var boxWidth = $(this).width();
                var textWidth = $('.scrolltext', this).outerWidth();
                if (textWidth > boxWidth) {
                    var animSpeed = textWidth * 16;
                    $(this).animate({ scrollLeft: (textWidth - boxWidth) }, animSpeed, 'linear', function () {
                        $(this).scrollLeft(0).trigger('marquee');
                    });
                }
            }).trigger('marquee');
        }

        function newNews() {
            $('.teleponto').scrollTop(0);

            $('.camara .teleponto').html('<p>' + news[callNews].description + '</p><br>');
            $('.televisao .tv').data('video', news[callNews].video);
            $('.televisao .imag').html('<img src="' + news[callNews].thumbnail + '" />');
            callNews++;
        }

        function timeRec() {
            $('.timerec').show();
            $('.timerec .bar').animate({ width: '100%' }, 400000, 'linear', function () {
                clearStudio(); $('.msgs.error').addClass('showing');
            });
        }

        $(document).keydown(function (e) {
            if (!$('.teleponto').is(':empty')) {
                if (e.keyCode == 40) {
                    var scrollDown = $('.teleponto').scrollTop();
                    $('.teleponto').scrollTop(scrollDown + 5);
                }
                if (e.keyCode == 38) {
                    var scrollUp = $('.teleponto').scrollTop();
                    $('.teleponto').scrollTop(scrollUp - 2);
                }
                if ($('.teleponto').scrollTop() + $('.teleponto').innerHeight() >= $('.teleponto')[0].scrollHeight) {
                    if (getNews) {
                        setTimeout(function () { runNews(); }, 1500);
                        getNews = false;
                    }
                }
            }
        });

        function setCanvas() {
            canvas.el.setAttribute('width', canvas.width);
            canvas.el.setAttribute('height', canvas.height);
        }

        function takeSnapshot(callback) {
            var video = $('.ecran')[0];

            var ct = canvas.el.getContext('2d');
            ct.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.el.toBlob(function (blob) {
                photoBlob = blob;
                callback();
            }, 'img/png', 1);
            // var data = canvas.el.toDataURL('image/png');
            // $('#photo-helper').attr('src', data);

            // requestAnimationFrame(draw);
        }

        function runNews() {
            if (typeof mediaRecorder !== 'undefined') {
                mediaRecorder.stop();
            }
            var src = $('.televisao .tv').data('video');
            $('.tv .ecran').attr('src', src);
            $('.tv .imag, .tv .destaques').hide();
            $('.camara .teleponto').html('');
            $('.camara .dot').removeClass('star');
            thousandths(99);
        }

        function thousandths(thou) {
            if (thou !== 0) {
                mili = setInterval(function () {
                    $('.conter .tho').text(--thou);
                    if (thou === 0) { thou = 99; }
                }, 10);
            } else {
                clearInterval(mili);
                $('.conter .tho').text('00');
            }
        }


        $('.tv .ecran').bind('ended', function () {
            $('.tv .imag, .tv .destaques').show();
            $('.camara .dot').addClass('star');

            thousandths(0);
            getNews = true;
            var newsNum = $('.start .noticias .active').data('num');
            if (callNews !== newsNum) {
                startRec();
                $('.ecran').attr('src', streamSrc);
            } else {
                clearStudio();
                $('.msgs.finish').addClass('showing');
                finishUpload();
                $.each(mediaStream.getVideoTracks(), function (key, track) {
                    track.stop();
                });
            }
        })
            .bind('timeupdate', function () {
                if (this.duration !== Infinity) {
                    var n = parseInt(this.duration - this.currentTime);
                    var sec = n.toString().length >= 2 ? n : '0' + n;
                    $('.conter .sec').text(sec);
                    /*if( n === 0 ) {
    
                    }*/
                }
            });

        function retryUploadVideoBlob(key, times, callback) {
            uploadVideoBlob(key, function (status) {
                if (status === 'success') {
                    callback('success');
                } else {
                    times--;
                    if (times > 0) {
                        retryUploadVideoBlob(key, times, callback);
                    } else {
                        callback('error');
                    }
                }
            });
        }

        function finishUpload() {
            var fd = new FormData();

            var toProccess = videoFilesBlob.count;

            if (toProccess > 0) {
                $('.send-btn icon').removeClass('seta_grande_dir').addClass('spin anim-spin');
                $('.uploadv').css({ visibility: 'visible' });
                $.each(videoFilesBlob, function (key, value) {
                    if (key != 'count') {
                        retryUploadVideoBlob(key, 3, function (status) {
                            if (status === 'success') {
                                toProccess--;
                            }
                            if (status === 'error') {
                                $('.area.ar-ok').fadeOut(250);
                                $('.area.ar-erro').fadeIn(250);
                            }
                            if (toProccess === 0) {
                                $('.send-btn icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
                                $('.uploadv').css({ visibility: 'hidden' });
                            }
                        });
                    }
                });
            }
        }

        function clearStudio() {
            callNews = 0;
            $('.camara .teleponto').html('');
            $('.camara .dot').removeClass('star');
            $('.televisao .tv').data('video', '');
            $('.televisao .tv video').removeAttr('src');
            $('.monit')[0].pause();
            $('.monit').removeAttr('src').hide();
            $('.televisao .imag').html('');
            $('.timerec').hide();
            $('.timerec .bar').css({ width: '0%' });
            $('.destaques .scrolltext').text('');
        }

    }
};


$.bwrChrome = /chrome/.test(navigator.userAgent.toLowerCase());
$.bwrFirefox = /firefox/.test(navigator.userAgent.toLowerCase());

if ($.bwrChrome === true || $.bwrFirefox === true) {
    $('.studio-record').addClass('brower');
}

if ($.jPlayer.platform.mobile === true || $.jPlayer.platform.tablet === true) {
    if ($('body.studio-record').length) {
        var pathName = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);
        window.location.pathname = pathName;
    }
}



jq.studioGallery = {
    init: function () {

        getThePlayer = function () {

            var playlist = handover.modalData.videos;
            var n = playlist.length;
            var plyGly = new jPlayerPlaylist({
                jPlayer: ".modal .screenplayer",
                cssSelectorAncestor: ".modal #plygallery"
            },
                playlist, {
                    supplied: 'webmv, m4v',
                    useStateClassSkin: true,
                    autoBlur: false,
                    smoothPlayBar: true,
                    keyEnabled: true,
                    toggleDuration: true,
                    size: {
                        width: '100%',
                        height: '100%',
                    },
                    play: function (v) {
                        var tipo = v.jPlayer.status.media.tipo;
                        if (tipo === 'news') {
                            $('.modal .player .destaques').css({ visibility: 'hidden' });
                            $('.modal .player .imagthumb').css({ visibility: 'hidden' });
                        }
                        if (tipo === 'user') {
                            $('.modal .player .destaques').css({ visibility: 'visible' });
                            $('.modal .player .imagthumb').css({ visibility: 'visible', backgroundImage: 'url(' + v.jPlayer.status.media.thumb + ')' });
                        }
                    },
                    pause: function () {
                        if (plyGly.current === n - 1) {
                            $('.modal .jp-video-play').show();
                            setTimeout(function () {
                                plyGly.select(0);
                            }, 10);
                        }
                    },
                }
            );

            $('.modal .jp-video-play').on('click', function () {
                var destaques = [];
                $.each(playlist, function (index, data) {
                    if (playlist[index].title !== '') {
                        destaques.push(playlist[index].title);
                    }
                });
                highlights(destaques.join(' • '));
            });

        };

        function highlights(text) {
            $('.modal .destaques .scrolltext').text(text);
            $('.modal .destaques').bind('marquee', function () {
                var boxWidth = $(this).width();
                var textWidth = $('.scrolltext', this).outerWidth();
                if (textWidth > boxWidth) {
                    var animSpeed = textWidth * 16;
                    $(this).animate({ scrollLeft: (textWidth - boxWidth) }, animSpeed, 'linear', function () {
                        $(this).scrollLeft(0).trigger('marquee');
                    });
                }
            }).trigger('marquee');
        }

        jq.pagination.init();
    }
};


