
jq.history = { init : function(){
	$t = $('.timeline');
	$line = $('.line', $t).flickity({
		prevNextButtons: false,
  		pageDots: false,
  		selectedAttraction: 0.2,
		friction: 0.8,
		lazyLoad: 2
	});

	$lineNav = $('.line-nav', $t).flickity({
		asNavFor: '.line',
		cellSelector: '.box',
		/*contain: true,*/
		cellAlign: 'left',
		prevNextButtons: false,
		pageDots: false,
		selectedAttraction: 0.4,
		friction: 1
	});
	$lineHist = $line.data('flickity');

	$t.on( 'lazyLoad', function( event, cellElement ) {
		resizeImg( $('.flickity-lazyloaded', cellElement ) );
	});

	var div = '<div class="titulo">História da Rádio e Tv</div>';
	$('.line-nav .box:first-child', $t).prepend(div) ;

	$('.box .next', $t).on( 'click', function() {
		$line.flickity('next');
	});

	$('.box .prev', $t).on( 'click', function() {
		$line.flickity('previous');
	});

	var arraAllYear = [];
	$('.box', $t).each( function(i){
		var ths = $('.ponto span', this).text();
		var tprv = $(this).prev().find('.ponto span').text();
		if( tprv === ths ){
			$(this).addClass('repeat');
		}
		arraAllYear[i] = $(this).data('year');
	});

	var getAllYear = function(yr) {
		var closeyr = null;
	    $.each(arraAllYear, function(){
	        if (closeyr === null || Math.abs(this - yr) < Math.abs(closeyr - yr)) {
	            closeyr = this;
	        }
	    });
	    return closeyr;
	};

	$('.history .inputyear').on('focus', function() {
		$(this).val('');
		$(this).on('keypress', function(e) {
			if( e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
		    	return false;
		    }
		});
		$(this).on('keyup', function() {
			var yr = $(this).val();
			if( yr.length === 4) {
				var el = $('.line .box[data-year='+getAllYear(yr)+']');
				var i = $(el).index();
				$lineHist.select(i);
			}
		});
	}).on('blur', function() {
		$(this).val('');
	});
}};
