
jq.cookies = { init : function(){
     /* COOKIES */
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    function checkCookie() {
        //cookie ber functionality removed because RTP implemented own system after RGPD (03/07/2018)
        var c = getCookie('cookieBox');
        if (c == '') {
            //$('#cookies, .fakebarcookies').addClass('show')
            $(window).on('load resize', function() {
                $('.fakebarcookies').height( $('#cookies').outerHeight() )
            });
        }
    };

    $('#cookies .btn-close').on('click', function() {
        setCookie('cookieBox', 'true', 365)
        $('#cookies, .fakebarcookies').removeClass('show')
        $(window).resize()
    }); 

    //checkCookie()

}};