jq.formContacts = { init : function(){
    $('#contact-form').on('submit', function(e) {
        e.preventDefault();
        $('.send-btn').attr('disabled', true).find('icon').removeClass('seta_grande_dir').addClass('spin anim-spin');
        API.submitContactsForm('contacts', $(this).serialize(), function(status, data) {
            if (status == 'success') {
                $('.form-group').fadeTo('250', 0.01);
                $('.msg-success').fadeTo('250', 1);
                setTimeout( function(){
                    $('#contact-form')[0].reset();
                    $('.form-group').fadeTo('250', 1).removeClass('has-error');
                    $('.msg-success').fadeTo('250', 0);
                    $('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
                }, 3500);
            } else {
                $('.form-group').removeClass('has-error');
                $.each(data.responseJSON, function(key, val){
                    if( key === key ) {
                        $('.form-group #'+key+'').closest('.form-group').addClass('has-error').find('.help-block').text(val);
                    }
                });
                $('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
            }
        });
    });
}};