
jq.trivial = { init : function(){

	/* home */
	$('.t-easy .classif').click( function() {
		$(this).toggleClass('active');
	    $('.conten').toggleClass('left');
	});

	$('.t-hard .classif').click( function() {
		$(this).toggleClass('active');
	    $('.conten').toggleClass('right');
	});

	/* QUIZ */
	var currentQuestionId, session;

	var processNewQuestion = function(data) {
		var $html = $(data.html);
		var $question = $html.find('.question');

		currentQuestionId = data.questionId;
		session = data.sessionId;

		$('.box-star').removeClass('showing');
		$('.box-quiz').html($html).addClass('showing').removeClass('right').removeClass('wrong');
		$('.box-quiz .openmodal').click(function(){
			openModalBind(this);
		});

		quizBinds();
	};

	var quizBinds = function() {
		// options:
		var selectedOption = false;
		$('.box-faq li').on('click', function(){
			if (selectedOption === false) {
				selectedOption = true;
				$(this).addClass('select');

				var data = {
					'questionId': currentQuestionId,
					'answerId': $(this).find('.answer').data('id'),
				};

				API.answerQuiz(session, data, function(status, data) {
					if (status == 'success') {
						$('.box-quiz').addClass(data.response);
						$('.btn.next').addClass('showing');
					}
				});
			}
		});

		$('.btn.next').on('click', function() {
			API.quizNextStep(session, function(status, data) {
				processNewQuestion(data);
			});
		});

		$('#quiz-form').on('submit', function(e) {
			e.preventDefault();
			var data = $(this).serialize();
			$('.send-btn').attr('disabled', true).find('icon').removeClass('seta_grande_dir').addClass('spin anim-spin');
			API.quizForm(session, data, function(status, data) {
				if (status == 'success') {
					$('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
					$('.box-finishing form').hide();
					$('.box-finishing .box-msg').show();	
					/*$('.box-btn .new')[0].click(); }*/
				} else {
					$('.form-group').removeClass('has-error');
					$.each(data.responseJSON, function(key, val){
						if( key === key ) {
							$('.form-group #'+key+'').closest('.form-group').addClass('has-error').find('.help-block').text(val);
						}
					});
					$('.send-btn').attr('disabled', false).find('icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
				}
			});
		});
	};

	// star
	$('.btn.star').on('click', function(){
		API.startQuiz($(this).data('difficulty'), function(status, data) {
			processNewQuestion(data);
		});
	});

	// next
	$('.btn.next').on('click', function(){
		validar(this);
		setTimeout( function(){
			$('.box-quiz').html('');
		}, 1500);
	});
	// finishing
	$('.btn.finish').on('click', function(){
		validar(this);
		setTimeout( function(){
			$('.box-quiz').removeClass('showing');
			$('.box-finishing').addClass('showing');
		}, 1500);
	});
	// save
	$('.btn.save').on('click', function(){
		window.location='/teste-memoria';
	});
	// new
	$('.btn.new').on('click', function(){
		$('.box-finishing').removeClass('showing');
		$('.box-quiz').removeClass('right wrong');
		$('.box-faq li').removeClass('select');
		$('.box-star').addClass('showing');
	});

	var validar = function(t) {
		var r =  $(t).data('r');
		var s = $('.box-faq li.select').index()+1;
		if( r === s ){
			$('.box-quiz').addClass('right');
		} else {
			$('.box-quiz').addClass('wrong');
		}
	};

}};