
jq.timeline = { init : function(){
 	
 	$(window).on('load', function() {
 		setTimeout( function(){
 			var html = $('#tpl-line').html()
	    	$('.area-line').html( html );
	    	createLine();
 		},500);
	})

    function createLine() {
		$line = $('.timeline .line').flickity({
			prevNextButtons: false,
	  		pageDots: false,
	  		selectedAttraction: 0.5,
			friction: 0.8
		});
		$t = $('.timeline');
		$col = $line.data('flickity');
		$input = $('.inputyear');
		
		$lastCol = $('.timeline .box:last-child');
		$firstCol = $('.timeline .box:first-child');

		var min = 90;
		var max = $col.getCellElements().length - 1;

		var rdm = Math.floor(Math.random() * (max - min + 1) + min);

		var img;
		var crtYear;

		function dots(elem, time) {
			var e = elem;
			var c = e.size();
			var r = randsort(c);

			elem.each(function(i) {
				var dot = $(this);
				var stopdots = setTimeout(function() {
			    	dot.addClass('select');			    
			    }, r[i]*time);
			    $('.filter .type', $t).on('click', function() {
			    	clearTimeout(stopdots);
			    });
			});
		}

		function randsort(c) {
		    var o = [];
		    for (var i = 0; i < c; i++) {
		        var n = Math.floor(Math.random()*c);
		        if( jQuery.inArray(n, o) > 0 ) --i;
		        else o.push(n);
		    }
		    return o;
		}

		function rumY(sele) {
			$input.attr('placeholder',''+crtYear+'');
			var timerdown = setInterval(function() {
				if( sele < crtYear ) {
			    	$input.val(++sele);
				}
				if( sele > crtYear ) {
			    	$input.val(--sele);
				}
			    if (sele === crtYear) {
			        clearInterval(timerdown);
			    }
			}, 1);
		}

		$line.on('cellSelect', function() {
			var crtyi = $input.val();
			var crtimg = $('.area-img .imag', $t).css('background-image');
			
			img = $('.is-selected', $t).data('img');	
			crtYear = $('.is-selected', $t).data('year');

			if( crtimg !== 'url("'+img+'")' ){
				$('.area-img .imag', $t).fadeOut(200, function() {
					$(this).css('background-image','url('+img+')').fadeIn(600);
				});
			}

			if( crtyi === '') {
				$input.val(crtYear).attr('placeholder',''+crtYear+'');
			} else {
				rumY(crtyi);
			}
		});

		$line.on('dragStart', function() {
			clearTimeout(arst); $('.arraste').removeClass('showing');
			clearDotHover(); 
		});

		$line.on('dragEnd', function() {
			$('.col .dot', $t).removeClass('select');
			$('.box', $t).removeClass('col');
			if( dotHover !== false ){ dotHoveRandom(); } 
		});

		$input.on('focus', function() {
			$(this).val('');
			$(this).on('keypress', function(e) {
				if( e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
			    	return false; 
			    }
			});
			$(this).on('keyup', function(e) {
				if( e.which === 13 ) {
					return false;
				}
				var cont = $(this).val();
				if( cont.length === 4) {

					clearTimeout(window.stopdots);
					$('.filter .type', $t).removeClass('active');
					$('.box .dot', $t).removeClass('select');
					$('.filter .type', $t).first().addClass('active');
				
					var el = $('.timeline .box[data-year='+cont+']');
					var i = $(el).index();

					$(el).addClass('col');
					$col.select(i); //$(this).blur();
					dots( $('.dot', el), 150);
				}
			});
		}).on('blur', function() {
			$(this).val(crtYear).attr('placeholder',''+crtYear+'');
		});

		$('.filter .type', $t).on('click', function() {
			var flt = $(this).data('filter');
			var el = $('.box').find('.dot[data-filter='+flt+']');

			$('.filter .type', $t).removeClass('active');
			$('.box .dot', $t).removeClass('select');
			$(this).addClass('active');

			if(jQuery.isEmptyObject(desktop)){
				dots($(el), 10);
			} else {
				$(el).addClass('select');
			}
		});

		var dotTimeOut;

		$('.timeline .box .dot').on('mouseenter', function(){
			window.clearTimeout(dotTimeOut);

			var that = this;
			dotTimeOut = setTimeout(function () {
				var img = $('.elem img', that).data('src');
				$('.elem img', that).attr('src',''+img+'');
			}, 500);
		});

		function falseLine() {
			var divLeft = '<div class="left-col"> </div>';
			var divRight = '<div class="right-col"> </div>';
			var falsebox = '<div class="box"> <div class="marco"></div> <div class="itens"><div class="center"><div class="dotfs"></div></div></div> </div>';

			$('.box:first-child', $t).prepend(divLeft);
			$('.box:last-child', $t).prepend(divRight);

			for (var i = 0; i < 60; i++) {
			    $('.box .left-col', $t).prepend(falsebox);
			    $('.box .right-col', $t).prepend(falsebox);
			}    
		} falseLine();

		$col.select(rdm, false, true);


		$('.timeline .box .dot .href').one('mouseenter', function(){
			clearDotHover(); dotHover = false;
		});

		var dotHover;
		function dotHoveRandom() {
			if(jQuery.isEmptyObject(desktop)){
				$('.box.is-selected').nextAll().slice(0,20).addClass('grupobox');
				$('.box.is-selected').prevAll().slice(0,20).addClass('grupobox');
				dotHover = setInterval( function() {
					var d = $('.grupobox .dot');
					var c = d.size();
					var r = Math.floor(Math.random()*c);
					var e = $('.grupobox .dot:eq('+r+')');
					$('.href', d).removeClass('hover');
					$('.href', e).addClass('hover');
					$('.elem img', e).attr('src',''+ $('.elem img', e).data('src') +'');
				}, 3000);
			}
		} dotHoveRandom();

		function clearDotHover() {
			clearInterval(dotHover);
			$('.dot .href').removeClass('hover');
			$('.box').removeClass('grupobox');
		}


		$('.loading').remove();
	}

}};
