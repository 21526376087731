var getThePlayer;
var plyModal;
jq.player = { init : function(){

    getThePlayer = function() {
        StopPlayDestaque();

        $('.modal .cell').each(function(key, value){
            var t = $(this);
            var plyid = t.data('id');
            var titulo = t.data('titulo');
            var video = t.data('video');
            var audio = t.data('audio');
            var poster = t.data('poster');
            var solution = ( video !== undefined ) ? video.split('.').pop() === 'flv' ? 'flash' : 'html' : 'html'; 

            if( audio !== undefined ){
                var wavesurfer = WaveSurfer.create({
                    container: '.modal #ply'+plyid+' .jp-seek-bar',
                    waveColor: '#021b2f',
                    progressColor: '#3fb7e7',
                    cursorColor: 'transparent',
                    barWidth: 3,
                    height: 240,
                });
                wavesurfer.load(audio);
                wavesurfer.on('loading', function (prcts) {
                    $('.jp-video-loading', t).show();
                });
                wavesurfer.on('ready', function () {
                    $('.jp-video-loading', t).hide();
                });
                $('.jp-seek-bar wave wave', t).addClass('jp-play-bar');
            }
            
            plyModal = $('.screenplayer', t).jPlayer({
                ready: function () {
                    $(this).jPlayer('setMedia', {
                        title: titulo,
                        m4v: video,
                        mp3: audio,
                        flv: video,
                        poster: poster,
                    });
                },
                swfPath: '/js/jplayer.swf',
                cssSelectorAncestor: '.modal #ply'+plyid+'',
                solution: solution,
                supplied: 'm4v, mp3, flv',
                useStateClassSkin: true,
                autoBlur: false,
                smoothPlayBar: true,
                keyEnabled: true,
                toggleDuration: true,
                size: {
                    width: '100%',
                    height: '100%',
                },
                play: function() {
                    $('.jp-video-loading', this).show();
                    if( $('.modal .backgrd-audio audio').length ){
                        $('.modal .backgrd-audio audio')[0].pause();
                        $('.modal .backgrd-audio audio')[0].currentTime = 0;
                        $('.modal .backgrd-audio').removeClass('play');
                    }
                },
                playing: function(){
                    $('.jp-video-loading', this).hide();
                },
            });
        });
    };
    
    $('.destaque .btn-play, .banner .btn-play').on('click', function(){        

        $('.box.is-selected').addClass('play');

        var video = $(this).data('video');
        var audio = $(this).data('audio');
        var plya = $(this).closest('.info').next('.player');
        var cont = $('.jp-seek-bar', plya);

        if( audio !== undefined ){
            var wavesurfer = WaveSurfer.create({
                container: cont[0],
                waveColor: '#021b2f',
                progressColor: '#3fb7e7',
                cursorColor: 'transparent',
                barWidth: 3,
                height: 200,
                pixelRatio: 1,
            });
            wavesurfer.load(audio);
           /*wavesurfer.on('loading', function (prcts) {
                $('.jp-video-loading', this).show();
            });
            wavesurfer.on('ready', function () {
                $('.jp-video-loading', this).hide();
            });*/
            $('.jp-seek-bar wave wave', plya).addClass('jp-play-bar');
        }

        plyStream = {
            m4v: video,
            mp3: audio,
        };

        $('.box.is-selected .screenplayer').jPlayer({
            ready: function () {
                $(this).jPlayer('setMedia', plyStream).jPlayer('play');
            },
            cssSelectorAncestor: '.box.is-selected .player',
            supplied: 'm4v, mp3',
            useStateClassSkin: true,
            autoBlur: false,
            smoothPlayBar: true,
            keyEnabled: true,
            toggleDuration: true,
            wmode: 'transparent',
            size: {
                width: '100%',
                height: '100%',
            },
            ended: function() {
                StopPlayDestaque();
            },
        });

    });

    $('.destaque .player .btn-close, .banner .player .btn-close').on('click', function() {
        StopPlayDestaque();
    });

    var StopPlayDestaque = function() {
        $('.box .screenplayer').jPlayer('destroy');
        $('.box .wave wave').remove();
        $('.box').removeClass('play');
    };

}};


