
jq.car = { init : function(){

	var cont = 0;
	var $virtual = $('.view-car .virtualcar');
	var areaH = '';

	$('.nav-car .dot').on('click', function(){
		var $t = $(this);
		
		var index = $t.closest('li').index()+1;
		var posit = $t.data('posit');
		areaH = $virtual.height();
		
		$('dt', $virtual).removeClass('showing');
		$('.nav-car li').removeClass('active');
		$t.closest('li').addClass('active');

		setTimeout( function(){
		    var animation = setInterval( function(){
		        if( cont < posit ) {
			    	cont++;
				}
				if( cont > posit ) {
			    	cont--;
				}
		        if(cont === posit){
		        	clearInterval(animation);
		            cont = posit;
		            $('.side'+index+'').addClass('showing');
		        }
		        $virtual.css({backgroundPosition:'center '+-(cont*areaH)+'px' });
		    },100);
		},50);    
	});

	$('.virtualcar dt').hover( function(){
		var posit = $(this).data('posit');
		$virtual.css({backgroundPosition:'center '+-(posit*areaH)+'px' });
	}, function(){
		$virtual.css({backgroundPosition:'center '+-(cont*areaH)+'px' });
	});

	$('.nav-car li:eq(0) .dot').click();

	$(window).on('resize', function() {
		$('.nav-car li.active .dot').click();
	});

	$(document).on('mouseover', '.modal .imag3.dot2', function() { 
		$('.modal .cell .inter02').stop().fadeIn(250);
	}).on('mouseout', function() { 
		$('.modal .cell .inter02').stop().fadeOut(250);
	});

	$(document).on('click','.modal .imag3.dot1', function(){
		$('.modal .motor')[0].play();
	});

	$(function(){
		var curDown = false, curXPos = 0;
	  	$('.virtualcar').on('pointermove mousemove touchmove', function(m) {
		    if(curDown === true){
		    	if( curXPos-m.pageX  >= 50) {
		    		curXPos = m.pageX;
		    		if(cont > -1 && cont < 11){ cont++; }
		    		$('dt', $virtual).removeClass('showing');
					$('.nav-car li').removeClass('active');
		    	}
		    	if( curXPos-m.pageX  <= -50) {
		    		curXPos = m.pageX;
		    		if(cont > 0 && cont < 12){ cont--; }
		    		$('dt', $virtual).removeClass('showing');
					$('.nav-car li').removeClass('active');
		    	}
		    	if(cont > -1 && cont < 12) {
		    		$virtual.css({backgroundPosition:'center '+-(cont*areaH)+'px' });
				}
		    }
	  	});
	  
		$('.virtualcar').on('pointerdown mousedown touchstart', function(m) {
		    curDown = true;
		    curXPos = m.pageX;
		});
	  
		$('.virtualcar').on('pointerup mouseup touchend', function(m) {
			curDown = false;
			if( !$('.nav-car .dot[data-posit="'+cont+'"]').closest('li').hasClass('active') ){
				$('.nav-car .dot[data-posit="'+cont+'"]').click();
			}
		});
	});

	//tv da carrinha
	$('.side1.dot4').on('click', function() {
    	if( $.jPlayer.platform.mobile !== true ) {
    		setTimeout(function(){ 
    			$('.modal .imag1.dot2').addClass('open');
    			$('.modal .tv-carro').show();
    			$('.modal .tv-carro')[0].play();
    		},0);
    	}
    });

	$(document).on('click','.modal .imag1.dot2.open', function(){
		var tv = $('.modal .tv-carro')[0];
	    if (tv.muted === true) {
	    	tv.muted = false;
	    } else {
	    	tv.muted = true;
	    }
	});
}};

