
$(window)
.on('load', function() {
	tsn = setTimeout( function() { if( $('body').hasClass('home') ){ $('.hamburger').addClass('showing'); } },2000);
    if ( $('body.home').length === 0 ) { $('.loading').remove(); }
    $('main').addClass('show');
    $('.btn-menu').addClass('show');
    resizeImg( $('.resize') );
    highlightsMobile();
    //url
    if( $('.itemurl').length ){ openModalBind( $('.itemurl') ); }
    $('.gsc-input input').removeAttr('id').attr('placeholder','Palavra-chave');
})
.on('resize', function() {
	resizeImg( $('.resize') );
    resizeImg( $('.flickity-lazyloaded') );
})
.on('scroll', function() {
    fixeMenu();
});

/* menu hanburger */
$('.hamburger').on('click', function() {
    if( $('header').hasClass('open-menu') ) { closeMenu(); } else { openMenu(); }
});

var openMenu = function() {
    clearTimeout(tsn);
	$('body').addClass('no-scroll');
    $('header').addClass('open-menu');
    $('.hamburger').removeClass('showing');
};

var closeMenu = function() {
	setTimeout(function() { $('body').removeClass('no-scroll'); },250);
    $('header').removeClass('open-menu');
    setTimeout( function(){
        $('menu').removeClass('search');
        $('.lupa').removeClass('select');
    }, 250);
};

var fixeMenu = function() {
    var wtop = $(this).scrollTop();
    if (wtop <= 20 ) {
        $('header .barra').removeClass('fixed');
    } else {
        $('header .barra').addClass('fixed');
    }
};

$('.lupa').on('click', function() {
    if( $(this).hasClass('select') ) {
        $(this).removeClass('select');
        $('menu').removeClass('search');
    } else {
        $(this).addClass('select');
        $('menu').addClass('search');
    }
});

arst = setTimeout( function() { if( $('.arraste').length ){ $('.arraste').addClass('showing'); } }, 5000);

var jq = {};
$(function(){
    var $body = $('body');
    jq.cookies.init();
    jq.player.init();

    if ($body.hasClass('home')) {
        jq.timeline.init();
    }
    if ($body.hasClass('collection-tv-content') || $body.hasClass('collection-tv-objects')) {
        jq.collection.init();
    }
    if ($body.hasClass('collection-radio-content') || $body.hasClass('collection-radio-objects')) {
        jq.collection.init();
    }
    if ($body.hasClass('collection-laboratory-objects')) {
        jq.collection.init();
    }
    if ($body.hasClass('virtual-rtp')) {
        jq.virtual.init(); jq.map.init();
    }
    if ($body.hasClass('virtual-car-360')) {
        jq.car.init(); 
    }
    if ($body.hasClass('history')) {
        jq.history.init();
    }
    if ($body.hasClass('exhibit-50years')) {
        jq.virtual50anos.init();
    }
    if ($body.hasClass('exhibit-hall') || $body.hasClass('exhibit-monitor') ) {
        jq.exhibitHall.init();
    }
    if ($body.hasClass('studio-record')) {
        jq.studio.init();
    }
    if ($body.hasClass('studio-gallery')) {
        jq.studioGallery.init();
    }
    if ($body.hasClass('trivial')) {
        jq.trivial.init();
    }
    if ($body.hasClass('trivial-basic') || $body.hasClass('trivial-advanced')) {
        jq.trivial.init();
    }
    if ($body.hasClass('contacts')) {
        jq.formContacts.init();
    }
    if ($body.hasClass('kiosk')) {
        jq.pagination.init();
    }

    if ($body.hasClass('videoteca_item') || $('#videoteca-container').length>0) {
        jq.videoteca.init();
    }
});

/* open modal */
var openModalBind = function (context) {
    var $el = $(context).find('.formodal');
    var type = $el.data('type');
    var id = $el.data('id');
    var requestData = handover.context || {};
    var item = null;
    if ($el.children().length > 0) {
        // No need to ajax request
        item = new modalItem($el.children().clone(), type);
        Modal.open(item);
    } else {
        API.getItems(type, id, requestData, function (status, data) {
            if (status == 'success') {
                var item = new modalItem(data.html, type);
                handover.modalData = data;
                Modal.open(item);
            }
        });
    }
};

$(document).on('click', '.openmodal', function() {
    openModalBind(this);
});


$(document).on('click', '.acceptance-required', function(e) {
    var acceptanceElementId = $(this).data('accept_control');
    acceptanceElement = $('#'+acceptanceElementId);
    if (!acceptanceElement.is(":checked")){
        console.log('Acceptance is required');
        var acceptanceContainer = acceptanceElement.parent();
        acceptanceContainer.css('color','red');
        acceptanceContainer.children('a').css('color','red');
        e.preventDefault();
        return false;
    }
});

$(document).on('click', '.acceptance-control', function(e) {
    var acceptanceContainer = $(this).parent();
    if (!$(this).is(":checked")){
        acceptanceContainer.css('color','red');
        acceptanceContainer.children('a').css('color','red');
    }else{
        acceptanceContainer.css('color','#fff');
        acceptanceContainer.children('a').css('color','#fff');
    }
});






/*Resize para imagens*/
function resizeImg(elem) {
    $(elem).each( function(){
        var scope = $(this);
        if ( !scope.parent('.resizeimg').length ) {
            scope.wrap( "<div class='resizeimg'></div>" );
        }
        var r = 0;
        var mw = scope.parent('.resizeimg').width();
        var mh = scope.parent('.resizeimg').height();
        var w = scope.width();
        var h = scope.height();
        var mr = mh/mw;
        var cr = h/w;
        scope.css({position:'absolute',margin:'auto',top:'-100%',right:'-100%',bottom:'-100%',left:'-100%',padding:'0'});
        if(cr === mr){
            scope.css({height: '100%'});
            scope.css({width: '100%'});
        }else if(cr >= mr){
            r = mw/w;
            scope.css({width: mw});
            scope.css({height: h*r});
        }else if(cr <= mr){
            r = mh/h;
            scope.css({height: mh});
            scope.css({width: w*r});
        }
    });
}

/* share */
function closeBoxLink(e) {
    var container = $('.share-btn .link');
    if (typeof e === 'undefined' || (!container.is(e.target) && container.has(e.target).length === 0)){
        $('.boxlink').fadeOut(150);
    }
}
$(document).mouseup( function(e){
    closeBoxLink(e);
});
$(document).on('click', '.share-btn .link', function() {
    $('.boxlink', this).fadeIn(250);
    $('.boxlink input', this).select();
});

desktop = $.jPlayer.platform;
var highlightsMobile = function () {
    if($.jPlayer.platform.mobile === true ) {
        $('.highlights').addClass('mobile');
    }
    if(!jQuery.isEmptyObject(desktop)){
        $('.tooltip').hide();
    }
};

/*$.each( $('textarea'), function() {
    var offset = this.offsetHeight - this.clientHeight;
    var resizeTextarea = function(el) {
        $(el).css('height', 'auto').css('height', el.scrollHeight + offset);
    };
    $(this).on('keyup input', function() { resizeTextarea(this); });
});*/
