var API = {
    urls: handover.urls.API,

    voting: false,

    doRequest: function (type, url, data, callback) {
        if (type == 'POST') {
            data._token = handover.token;
        }
        
        if (data === null) {
            data = {};
        }

        data.locale = handover.locale;
        $.ajax({
            type: type,
            url: url,
            data: data,
            dataType: 'json',
            success: function (data) {
                callback('success', data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback('error', jqXHR);
            },
        });
    },

    getItems: function (type, id, requestData, callback) {
        var url = this.urls['get-' + type];
        url = url.replace(":id:", id);

        this.doRequest('GET', url, requestData, callback);
    },

    getCollection: function (type, data, callback) {
        var url = this.urls['get-' + type];

        this.doRequest('POST', url, data, callback);
    },

    vote: function (id, type, value, callback) {
        if (this.voting) {
            callback('busy', null);
            return;
        }
        this.voting = true;

        var url = (this.urls.vote).replace(":id:", id);

        var that = this;
        this.doRequest('POST', url, {type: type, value: value}, function(status, response){
            that.voting = false;
            callback(status, response);
        });
    },

    startQuiz: function(type, callback) {
        var url = (this.urls['get-quiz-new']).replace(":type:", type);

        this.doRequest('GET', url, null, callback);
    },

    answerQuiz: function(id, data, callback) {
        var url = (this.urls['quiz-answer']).replace(":id:", id);

        this.doRequest('POST', url, data, callback);
    },

    quizNextStep: function(id, callback) {
        var url = (this.urls['quiz-next-step']).replace(":id:", id);

        this.doRequest('GET', url, null, callback);
    },

    quizForm: function(id, data, callback) {
        var url = (this.urls['quiz-form']).replace(":id:", id);

        this.doRequest('POST', url, data, callback);
    },

    startRecord: function(n, callback) {
        var url = (this.urls['studio-new-session']).replace(":n:", n);

        this.doRequest('GET', url, null, callback);
    },

    uploadVideo: function(session, data, callback) {
        $.ajax({
            type: 'POST',
            url: this.urls['studio-upload-video'].replace(":session:", session),
            data: data,
            processData: false,
            contentType: false,
            success: function (data) {
                callback('success', data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback('error', jqXHR);
            },
        });
    },

    uploadStudioPhoto: function(session, data, callback) {
        $.ajax({
            type: 'POST',
            url: this.urls['studio-upload-photo'].replace(":session:", session),
            data: data,
            processData: false,
            contentType: false,
            success: function (data) {
                callback('success', data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback('error', jqXHR);
            },
        });
    },

    finishRecord: function(session, data, callback) {
        var url = (this.urls['studio-finish-session']).replace(":session:", session);

        this.doRequest('POST', url, data, callback);
    },

    submitContactsForm: function(form, data, callback) {
        var url = (this.urls['submit-form']).replace(":form:", form);

        this.doRequest('POST', url, data, callback);
    }
};
