
jq.collection = { init : function(){
    if($('.destaque .line').length > 0){
        if( $('.destaque.audio').length ){
            $t = $('.destaque.audio');
        }
        if( $('.destaque.video').length ){
            $t = $('.destaque.video');
        }

        $line = $('.line', $t).flickity({
            prevNextButtons: false,
            selectedAttraction: 0.1,
            friction: 1,
            draggable: true,
        });

        $line.on('cellSelect', function() {
            setTimeout( function(){
                StopPlayDestaque();
            },500);
        });

        var StopPlayDestaque = function() {
            $('.box .screenplayer').jPlayer('destroy');
            $('.box .wave wave').remove();
            $('.box').removeClass('play');
        };
    }

    jq.pagination.init();
}};