var Modal = {
    $el: $('.modal'),
    $slider: null,

    open: function(item){
        var $el = this.$el;
        $el.find('.modal-contem').html(item.getElement);
        $el.fadeTo(250, 1);
        $('body').addClass('no-scroll');
        this.doBinds();
        getThePlayer();
        if ( $el.find('.slider').length ) {
            $el.find('.slider').flickity({
                prevNextButtons: true,
                selectedAttraction: 0.1,
                friction: 1,
                draggable: true,
            });
            $el.find('.modal-contem .report .info').fadeOut(0);
            this.$slider = $el.find('.slider').data('flickity');
            this.$slider.on( 'cellSelect', function() {
                var prtSld =  $(this.selectedCell.element).data('parent');
                var prtInf =  $el.find('.modal-contem .report .info:visible').data('parent');
                if( prtSld != prtInf ) {
                    $el.find('.modal-contem .report .info').fadeOut(0);
                    $el.find('.modal-contem .report .info[data-parent="'+prtSld+'"]').fadeIn(250);
                }
                $('.modal .cell').each( function() {
                    $('.screenplayer', this).jPlayer('stop');
                });
            });
            this.$slider.on('dragStart', function() {
                $('.cell .player .jp-video-play').css({'z-index':0});
            });
            this.$slider.on('dragEnd', function() {
                $('.cell .player .jp-video-play').css({'z-index':3});
            });
            this.$slider.select(0);
        }   
        var imgDefer = $('img.lazy', $el);
        for (var i=0; i<imgDefer.length; i++) {
            if( imgDefer[i].getAttribute('data-src') ) {
                imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src') );
            }
        }
        this.backgroundAdap();
        $el.scrollTop(0);
        backgrdPlayer();
    },

    close: function(){
        var thisBuffer = this;
        this.$el.fadeOut(250, 0).promise().done(function(){
            thisBuffer.$el.find('.modal-contem').html('');
            $('body').removeClass('no-scroll');
        });
    },

    doBinds: function(){
        var thisBuffer = this;
        this.$el.find('.modal-close, .modal-backgrd').on('click', function(event){
            thisBuffer.close();
        });
        this.$el.find('.btn-lupa').on('click', function(event) {
            thisBuffer.zoomObj(event);
        });
    },

    backgroundAdap: function(){
        this.$el.find('.modal-backgrd').height(this.$el.find('.modal-contem').outerHeight());
    },

    // ZOOM objects
    zoomObj: function(event){
        var $elzoom = $(event.currentTarget).closest('.object');
        if( !$elzoom.hasClass('zooming') ) {
            $('img', $elzoom).fadeOut(150, function() {
                $elzoom.addClass('zooming');
                $(this).fadeIn(150);
                $(this).panzoom({ 
                    $zoomIn: $('.boxzoom .btn-more'),
                    $zoomOut: $('.boxzoom .btn-less'),
                    cursor: '',
                    minScale: 1,
                    increment: 0.4,
                    contain: 'invert',
                }).panzoom('zoom', 2.8);
                $(this).on('mousewheel.focal', function( e ) {
                    e.preventDefault();
                    var delta = e.delta || e.originalEvent.wheelDelta;
                    var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
                    $(this).panzoom('zoom', zoomOut, {
                        increment: 0.2,
                        focal: e,
                    });
                });
            });
        } else {
            $('img', $elzoom).fadeOut(150, function() {
                $(this).panzoom('destroy');
                $(this).removeAttr('style');
                $elzoom.removeClass('zooming');
                $(this).fadeIn(150);
            });
        }
        if( $('.modal .slider').length ) {
            setTimeout( function(){
                this.$slider.reloadCells(); 
            },400);
        }
    }

};

var backgrdPlayer = function() {
    var el = $('.modal .backgrd-audio');
    var ad = $('audio', el)[0];
    el.on('click', function() {
        if( $(this).hasClass('play') ) {
            $(this).removeClass('play'); ad.pause(); ad.currentTime = 0;
        } else {
            $(this).addClass('play'); ad.play();
            $('.modal .cell').each( function() {
                $('.screenplayer', this).jPlayer('pause');
            });
        }
    }); 
    if(jQuery.isEmptyObject(desktop)){
        setTimeout( function(){ el.click(); },1000);
    }
};

var modalItem = (function () {

    var init = function(that) {
        var $el = that.getElement();

        // search for vote system:
        var $voteWrapper = $el.find('.felt');
        if ($voteWrapper.length > 0) {
            // Gotta bind the buttons if none is selected;
            if ($voteWrapper.find('li.select').length === 0) {
                $voteWrapper.find('li').on('click', function(event){
                    var $currentLi = $(event.currentTarget);
                    var value = $currentLi.data('value');

                    API.vote(that.getId(), that.getType(), value, function(status, data) {
                        if (status == 'success') {
                            $currentLi.addClass('select');

                            $.each($voteWrapper.find('li'), function(key, value) {
                                $(value).off('click').find('.vote').text(data[$(value).data('value')]);
                            });
                        }

                        if (status == 'busy') {
                            alert('chillout na votação');
                        }
                    });
                });
            }
        }
    };

    var modalItem = function(element, t) {
        // Initialization:
        var $el = $(element);
        var type = t;
        var id = $el.data('id');

        // Functions:
        this.getElement = function(){
            return $el;
        };

        this.getType = function(){
            return type;
        };

        this.getId = function(){
            return id;
        };

        init(this);
    };


    // cls.prototype = {
    //     getElement: function(){
    //         return this.$el;
    //     }
    // };

    return modalItem;

})();
