
jq.virtual = { init : function(){
	$dot = $();
	$line = $('.timeline .line').flickity({
		prevNextButtons: false,
  		pageDots: false,
		freeScroll: true,
		imagesLoaded: true,
		cellSelector: '.active',
		contain: true,
	});

	clearTimeout(arst);
	$line.on('dragStart', function() {
		 $('.arraste').removeClass('showing');
	});

	$('.line-nav li').on( 'click', function() {
		var num = $(this).data('posit'); 
		var selec = $('.flickity-slider .box').data('posit');
		if( num !== selec ){
			$dot = $(this);
			$('.line-nav li').removeClass('active');
			$dot.addClass('active');
			$('.timeline .line .box').removeClass('active');
			$('.timeline .line .box[data-posit="'+num+'"]').addClass('active');
			$('.flickity-slider .box').addClass('out'); elArraste();
			setTimeout( function(){
				var oldCell = $('.flickity-slider .box')[0];
				var newCell = $('.timeline .line .box.active').clone();
				$line.flickity('remove', oldCell );
				$line.flickity('insert', newCell[0], 1 );
				$line.flickity('select', 1, false, true);
				$('.flickity-slider .box').removeClass('active');
			}, 250);
			
		}
	});

	function elArraste() {
		$('.arraste').removeClass('showing');
		var docw = $('.timeline .line').width();
		var elew = $('.timeline .line .box.active').width();
		$line.flickity('unbindDrag');
		if( docw < elew ){
			$line.flickity('bindDrag');
			setTimeout( function() { $('.arraste').addClass('showing'); },250);
		}
	}
	
	//LAZY LOADING imagens
    $(window).on('load', function() {
        var imgDefer = $('img.lazy');
        for (var i=0; i<imgDefer.length; i++) {
            if( imgDefer[i].getAttribute('data-src') ) {
                imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src') );
            }
        }
    });


	$('.line-nav li:eq(0) .dot').click();

	$(document).on('click','.modal .imag1', function(){
		var som = $(this).data('som');
		var sounds = document.getElementsByTagName('audio');
		for(i=0; i<sounds.length; i++) {
			sounds[i].pause(); sounds[i].currentTime = 0;
		}
		$('.modal audio.'+som+'')[0].play();
	});

	$('.line-nav .next .btn').on('click', function(){
		if( $dot.is(':last-child') ){
            $('.line-nav li:eq(0)').click();
        } else {
			$dot.next().click();
		}
    });

	$(document).on('click','.area12 .dot0', function(){
		$('.line-nav li:eq(12)').click();
	});
}};
