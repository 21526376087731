
jq.map = { init : function(){

        /*          MAP          */
        // Estilos para um mapa bonito
        var mapStyles = [
            {"featureType": "road","stylers": [{"hue": "#3fb7e7"},{"saturation": -79}] },
            {"featureType": "poi","stylers": [{"saturation": -78},{"hue": "#010f1c"},{"lightness": -47},{"visibility": "off"}] },
            {"featureType": "road.local","stylers": [{"lightness": 22}] },
            {"featureType": "landscape", "stylers": [{"hue": "#010f1c"},{"saturation": -11}] },
            {"featureType": "water","stylers": [{"hue": "#010f1c"},{"lightness": 8}] },
            {"featureType": "road.local","stylers": [{"weight": 1.3},{"lightness": 30}] },
            {"featureType": "transit","stylers": [{"visibility": "simplified"},{"hue": "#3fb7e7"},{"saturation": -16}] },
            {"featureType": "transit.line","stylers": [{"saturation": -72}] },
        ];

        //Definicoes do mapa
        mapOptions = {
            zoom:16,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: mapStyles,
            scrollwheel: true, //fazer zoom com o mouse well
            disableDoubleClickZoom: 'disables', //duplo click para fazer zoom
            draggable: true,
            panControl: true, //setas de andar para os lados
            panControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            zoomControl: true, //mais e menos do zoom
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            //scaleControl: false, //traco de escala
            streetViewControl: false, //boneco do street view
            streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            disableDefaultUI: true,
            moveTo: { point: google.maps.ZoomControlStyle.SMALL }
        };

        var map;
        function initMap() {
            var areaMap = $('.modal #mapa')[0];
            var map = new google.maps.Map(areaMap, mapOptions);
                map.setCenter( new google.maps.LatLng(38.7592081,-9.1177593) );
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(38.7592081,-9.1177593),
                map: map,
                icon: '/img/logos/marker.png'
            });
        }
        
        $('.virtual-rtp .moreinfo').click(function(){
            setTimeout( function() {
                google.maps.event.addDomListener(this, 'click', initMap() );
            },0);
        });

        $(window).on('load', function() {
            if( $('.itemurl').length ) {
                google.maps.event.addDomListener(this, 'load', initMap() );
            }
        });

    }
};
