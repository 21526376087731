jq.pagination = { 
    init : function(){
        // Category Selection and SearchBox
        var data = {
            'category': null,
            'type': null,
            'query': null,
            'page': null,
            'select': null,
            'decade': null,
        };

        var doingSearch = false;

        // Pagination
        var bindPagination = function () {
            $('.pagination .chevron').click(function (event) {
                event.preventDefault();
                if ($(this).hasClass('disabled')) {
                    return;
                }
                var direction = $(this).data('direction');
                data.page = $(this).parents('.pagination').data('current-page');
                data.page += direction == 'back' ? (-1) : 1;
                
                $('.highlights').fadeTo(250,0, function() {
                    doSearch();
                    $('html,body').stop().animate({scrollTop: $('.filtro').offset().top-60 }, 500, 'swing');
                });
            });
        };

        bindPagination();
        if(typeof $('input#decade') !== 'undefined') {
            data.decade = $('input#decade').val();
        }

        var bindCollectionItems = function () {
        $('.openmodal').on('click', function(){
                var $el = $(this).find('.formodal');
                var type = $el.data('type');
                var id = $el.data('id');
                var requestData = handover.context || {};

                API.getItems(type, id, requestData, function (status, data) {
                    if (status == 'success') {
                        var item = new modalItem(data.html, type);
                        Modal.open(item);
                    }
                });
            });
        };

        var doSearch = function () {
            if (doingSearch) {
                return;
            }
            var type = handover.pageType;
            doingSearch = true;
            $('.do-search icon').removeClass('seta_grande_dir').addClass('spin anim-spin');
            API.getCollection(type, data, function (status, data) {
                if (status == 'success') {
                    $('.highlights').html(data.html.content);
                    $('.pagina').html(data.html.pagination);
                    $('.highlights').fadeTo(0,1).find('.area').fadeTo(0,0).each(function(i) {
                        $(this).delay(100*i).fadeTo(250,1);
                    });
                    $('.do-search icon').removeClass('spin anim-spin').addClass('seta_grande_dir');
                    if (history.pushState) {
                        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?';
                        $.each(data, function(key, value){
                            if (key != 'html' && value !== null && value !== '') {
                                newurl += key + '=' + value + '&';
                            }
                        });
                        newurl = newurl.slice(0, -1);
                        window.history.pushState({ path: newurl }, '', newurl);
                    }
                    bindPagination();
                    bindCollectionItems();
                    easterEgg(data);
                }
                doingSearch = false;
            });
        };

        $('select#category-id').change(function () {
            data.category = $(this).val();
            data.page = null;
        });

        $('select#type-id').change(function () {
            data.type = $(this).val();
            data.page = null;
        });

        $('input#query').blur(function () {
            data.query = $(this).val();
            data.page = null; 
        }).keydown(function(event) {
            if (event.keyCode == 13) {
                data.query = $(this).val();
                data.page = null;
                doSearch();
            }
        });

        $('.do-search').click(function () {
            if (data.category !== null || data.query !== null) {
                doSearch();
            }
        });

        $('select').selectric({
            onOpen: function(el, Selectric) {
                data.select = Selectric.state.currValue;
            },
            onClose: function(el, Selectric) {
                var curr = Selectric.state.currValue;
                Selectric.elements.wrapper.addClass('white');
                if( curr === 0 ) {
                    Selectric.elements.wrapper.removeClass('white');
                }
                if( curr !== data.select ) {
                    doSearch();
                }
            },
        });

        function easterEgg(data) {
            if( $('.collection-tv-content').length && data.category === '35' && data.query === 'site antigo') {
                $('.pagina .noitens').prepend('<a class=link href="https://www.youtube.com/watch?v=L4Hv1Ul7RX0" target="_blank" >►</a>');
            } else {
                $('.pagina .noitens .link').remove();
            }
        }
    }
};